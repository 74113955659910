import { template as template_3601012b915347e8b61d140677979428 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3601012b915347e8b61d140677979428(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
